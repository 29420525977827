import { PRODUCT_TYPE } from "./productType";
import { createTransform } from "redux-persist";

export const authTokenTransform = createTransform(
  (inboundState) => {
    // Only persist the authToken
    return { authToken: inboundState.authToken };
  },
  (outboundState) => outboundState // No need to modify on rehydrate
);

const initialState = {
  authToken: null,
  name: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_TYPE.PRODUCT_REDUCER_TYPE:
      return { ...state, ...action.params };
    default:
      return state;
  }
};

export default productReducer;
