import { PRODUCT_TYPE } from "./productType";

export const getProductAction = (payload, meta) => {
  return {
    type: PRODUCT_TYPE.PRODUCT_SAGA_TYPE,
    payload,
    meta,
  };
};

export const productReducerAction = (payload) => {
  return {
    type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
    params: payload,
  };
};
