export const navigationConstants = [
  {
    iconName: "local_shipping",
    navigation: "/Home",
  },
  {
    iconName: "account_circle",
    navigation: "/ManageProducts",
  },
];

export const mockJsonData = [
  {
    id: "d2PUwk8yMSpPp3w6QLfv",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6QLfe",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6QLfq",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6QLfw",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6QLf5",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6QLf8",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6QLf9",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6QLfk",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6QLfs",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6QLfa",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6QLfl",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6QLfm",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6QLfx",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSpPp3w6dLfv",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yMSePp3w6QLfv",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
  {
    id: "d2PUwk8yqSpPp3w6QLfv",
    registrationNumber: "TN00AB1234",
    emissionStandard: "BS6",
    ownership: "First",
    kmDriven: "1000",
    make: "2008",
    model: "Tata Ace",
    fc: "1234",
    insurance: "1234",
    passing: "1234",
    feet: "10",
    bodyType: "Open",
    tax: "1234",
    price: "50000",
    vehicleImage:
      "https://www.shutterstock.com/shutterstock/photos/2469501197/display_1500/stock-photo-white-truck-driving-on-the-asphalt-road-in-rural-landscape-at-sunset-2469501197.jpg",
  },
];

export const enquiryListData = [
  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "1235",
  },
  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "1233",
  },
  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "1238",
  },
  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "1238",
  },
  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "1232",
  },
  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "1230",
  },
  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "12312",
  },
  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "12352",
  },
  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "12323",
  },
  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "12342",
  },

  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "123424",
  },

  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "123232",
  },

  {
    name: "sample",
    mobileNumber: 1234567890,
    message: "sample",
    vehicleId: "1234",
  },
];

export const tableSelectConstants = [
  {
    value: 5,
  },
  {
    value: 10,
  },
  {
    value: 20,
  },

  {
    value: 50,
  },

  {
    value: 100,
  },
];

export const footerItems = [
  {
    id: 1,
    icon: "location_on",
    value: "No34, Nanbargal Nagar, poonamalle, Chennai-600 056",
    label: "Address",
  },

  {
    id: 2,
    icon: "mail",
    value: "harichandra2@gmail.com",
    label: "Mail Address",
  },

  {
    id: 3,
    icon: "chat",
    value: "91766 56224",
    label: "Whatsapp number",
  },

  {
    id: 4,
    icon: "phone_iphone",
    value: "94440 56224, 94450 95473, 7358171107",
    label: "Contact Number",
  },
];
