import { call, put, takeLatest } from "redux-saga/effects";
import { PRODUCT_TYPE } from "./productType";
import { toast } from "react-toastify";

function* fetchAuthToken(data) {
  try {
    if (
      data?.email === "sample@gmail.com" &&
      data?.password === "sWWmapleq95035&@"
    ) {
      yield put({
        type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
        params: {
          authToken: "6437qtrgfusdufwt37tgdfsydgfysdyfy",
        },
      });

      toast.success("logged in succesfully");
    } else {
      toast.error("Invalid user credentials");
    }
  } catch (error) {
    toast.error("oops something went wrong");
  }
}

function* productWatcherSaga(action) {
  try {
    const apiMap = {
      GET_USER_DETAILS: fetchAuthToken,
    };
    const apiSaga = apiMap[action.meta];
    if (apiSaga) {
      yield call(apiSaga, action?.payload);
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* productSaga() {
  yield takeLatest(PRODUCT_TYPE.PRODUCT_SAGA_TYPE, productWatcherSaga);
}
