import React, { useMemo } from "react";
import TableComponent from "../components/TableComponent";
import { enquiryListData } from "../utils/ProductConstants";

function EnquiryListScreen() {
  const HeaderConstants = useMemo(() => {
    return [
      {
        Title: "Vehicle Id",
        flexWidthClass: "flex-1",
        customRender: true,
        keyToMap: "vehicleId",
        renderItem: (value) => (
          <h5 className="text-sm text-black">{value?.vehicleId}</h5>
        ),
      },
      {
        Title: "Mobile Number",
        flexWidthClass: "flex-1",
        customRender: true,
        keyToMap: "mobileNumber",
        renderItem: (value) => (
          <p className="text-sm text-slate-500">{value?.mobileNumber}</p>
        ),
      },
      {
        Title: "User Name",
        flexWidthClass: "flex-1",
        customRender: true,
        keyToMap: "name",
        renderItem: (value) => (
          <h5 className="text-sm text-black">{value?.name}</h5>
        ),
      },
      {
        Title: "Message",
        flexWidthClass: "flex-1",
        customRender: true,
        keyToMap: "message",
        renderItem: (value) => (
          <p className="text-xs text-slate-500">{value?.message}</p>
        ),
      },
    ];
  }, []);

  const renderTableComponent = useMemo(() => {
    return (
      <TableComponent headerDetails={HeaderConstants} data={enquiryListData} />
    );
  }, [HeaderConstants]);
  return (
    <div className="p-10 flex flex-col max-w-screen-lg h-full m-auto">
      <div className=" mb-4">
        <h3 className="font-medium text-2xl">Enquiry</h3>
      </div>
      {renderTableComponent}
    </div>
  );
}

export default React.memo(EnquiryListScreen);
