import React, { useCallback, useMemo, useState } from "react";
import { mockJsonData } from "../utils/ProductConstants";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import TableComponent from "../components/TableComponent";
import Button from "../components/Button";
import AddEditFrame from "./AddEditFrame";
import ToggleSwitch from "../components/ToggleSwitch";

function ManageProducts() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [markAsSold, setMarkAsSold] = useState(false);

  const HeaderConstants = useMemo(() => {
    return [
      {
        Title: "Vehicle Model",
        flexWidthClass: "flex-1",
        customRender: false,
        keyToMap: "model",
      },
      {
        Title: "Vehicle Image",
        flexWidthClass: "flex-1",
        customRender: true,
        keyToMap: "vehicleImage",
        renderItem: (value) => (
          <img
            className="w-[90px] h-[60px] object-cover rounded-lg"
            alt="thumb"
            src={value?.vehicleImage}
          />
        ),
      },
      {
        Title: "Price",
        flexWidthClass: "w-1/6",
        customRender: true,
        keyToMap: "price",
        renderItem: (value) => (
          <p className="text-sm text-slate-500">{value?.price} rs</p>
        ),
      },
      {
        Title: "Actions",
        flexWidthClass: "w-1/6",
        customRender: true,
        renderItem: (value) => (
          <Button
            iconName="edit"
            handlePress={() => setModalOpen(true)}
            styleClass="w-[30px] flex justify-center items-center border-inherit h-[30px]"
            iconBtn={true}
          />
        ),
      },
    ];
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setMarkAsSold(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hadleAddPageNavigation = useCallback(() => {
    navigate("/AddProducts");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCollapseItem = useCallback((item) => {
    return (
      <div className="grid grid-cols-4  gap-4">
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Make :</label>
          <p className="text-sm font-medium text-slate-500 ">{item?.make}</p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">
            Emission Standard:
          </label>
          <p className="text-sm font-medium text-slate-500 ">
            {item?.emissionStandard}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">
            Reg Number:
          </label>
          <p className="text-sm font-medium text-slate-500 ">
            {item?.registrationNumber}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Fc:</label>
          <p className="text-sm font-medium text-slate-500 ">{item?.fc}</p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Passing:</label>
          <p className="text-sm font-medium text-slate-500 ">{item?.passing}</p>
        </div>

        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Insurance:</label>
          <p className="text-sm font-medium text-slate-500 ">
            {item?.insurance}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Feet:</label>
          <p className="text-sm font-medium text-slate-500 ">{item?.feet}</p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Km Driven:</label>
          <p className="text-sm font-medium text-slate-500 ">
            {item?.kmDriven}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">OwnerShip:</label>
          <p className="text-sm font-medium text-slate-500 ">
            {item?.ownership}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Tax:</label>
          <p className="text-sm font-medium text-slate-500 ">{item?.tax}</p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Body type:</label>
          <p className="text-sm font-medium text-slate-500 ">
            {item?.bodyType}
          </p>
        </div>
      </div>
    );
  }, []);

  const EditItem = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleToggle = useCallback(() => {
    setMarkAsSold(!markAsSold);
  }, [markAsSold]);

  const renderTableComponent = useMemo(() => {
    return (
      <TableComponent
        collapseable={true}
        headerDetails={HeaderConstants}
        data={mockJsonData}
        renderCollapse={(item) => renderCollapseItem(item)}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HeaderConstants, mockJsonData]);

  const renderModal = useMemo(() => {
    return (
      <Modal
        styles="w-full max-w-[800px]"
        closeModal={closeModal}
        isOpen={modalOpen}
      >
        <div className="w-full">
          <div className="flex-row flex p-5 justify-between items-center">
            <h3 className="font-medium text-xl ">Edit Item</h3>
            <Button
              styleNone={true}
              iconName={"close"}
              handlePress={closeModal}
              iconBtn={true}
            />
          </div>
          <div className="overflow-y-auto max-h-[520px]  px-5  flex-1">
            <AddEditFrame isSold={markAsSold} />
          </div>
          <div className="px-5 py-3 flex justify-between items-center border-t-2">
            <div className="flex  items-center gap-4">
              <label className="text-sm font-medium text-gray-700">
                Mark as Sold
              </label>
              <ToggleSwitch onToggle={handleToggle} isOn={markAsSold} />
            </div>

            <Button
              label={"Edit Item"}
              handlePress={EditItem}
              styleClass="w-[160px] py-2 px-4"
            />
          </div>
        </div>
      </Modal>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen, markAsSold]);

  const renderPageHeader = useMemo(() => {
    return (
      <div className="flex flex-row justify-between items-center mb-4">
        <h3 className="font-medium text-2xl mb-3">Manage products</h3>
        <div>
          <Button
            handlePress={hadleAddPageNavigation}
            styleClass="w-[160px] py-2 px-4"
            label="Add Product"
          />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-10 flex flex-col max-w-screen-lg h-full m-auto">
      {renderPageHeader}
      {renderTableComponent}
      {renderModal}
    </div>
  );
}

export default React.memo(ManageProducts);
