import React, { useState, useMemo, useCallback } from "react";
import { tableSelectConstants } from "../utils/ProductConstants";
import { isValidString } from "../utils/productHelper";
import Pagination from "./PaginationBtns";
function TableComponent({
  data,
  headerDetails,
  collapseable = false,
  renderCollapse,
}) {
  const [isCollapsed, setIsCollapsed] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const tatlaPages = useMemo(() => {
    return Math?.ceil(data?.length / rowsPerPage);
  }, [data, rowsPerPage]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const currentPageData = useMemo(() => {
    return data?.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  }, [currentPage, rowsPerPage, data]);

  const handleRowChange = useCallback((e) => {
    if (isValidString(e?.target?.value)) {
      setCurrentPage(1);
      setRowsPerPage(e?.target?.value);
    }
  }, []);

  const renderTableHeader = useMemo(() => {
    return (
      <div className={`p-2 flex border-b-2 pr-7 `}>
        {collapseable && <div className={`w-[40px]`} />}
        {headerDetails?.map((item, index) => {
          return (
            <div key={index} className={`${item?.flexWidthClass}`}>
              <label className="text-sm text-slate-500">{item?.Title}</label>
            </div>
          );
        })}
      </div>
    );
  }, [collapseable, headerDetails]);

  const renderFooter = useMemo(() => {
    return (
      <div className="p-3  border-t-2 flex flex-row justify-between items-center">
        <div className="flex flex-row  items-center gap-5 ">
          <div className="flex flex-row  items-center ">
            <label className="text-sm font-medium text-gray-700 mr-2">
              Total item:
            </label>
            <p className="text-sm font-medium text-black">{data?.length}</p>
          </div>
          <div>
            <label
              htmlFor="rows-per-page"
              className="text-sm font-medium text-gray-700 mr-2"
            >
              Rows per page:
            </label>
            <select
              id="rows-per-page"
              value={rowsPerPage}
              onChange={handleRowChange}
              className="px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              {tableSelectConstants?.map((item, index) => {
                return (
                  <option key={index} value={item?.value}>
                    {item?.value}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div>
          <Pagination
            onPageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={tatlaPages}
          />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, rowsPerPage, tatlaPages, currentPage]);

  const handleCollapse = useCallback(
    (id) => {
      if (isCollapsed === id) {
        setIsCollapsed(null);
      } else {
        setIsCollapsed(id);
      }
    },
    [isCollapsed]
  );

  const renderTableContent = useMemo(() => {
    return (
      <div className="flex-1 h-full max-h-[480px] overflow-y-scroll ">
        {currentPageData?.map((item, index) => {
          return (
            <div key={item?.id} className="border-b-2">
              <div key={index} className="p-2 flex items-center">
                {collapseable && (
                  <div className={`w-[40px]`}>
                    <button onClick={() => handleCollapse(item?.id)}>
                      <span
                        className={`material-symbols-outlined text-slate-500  ${
                          isCollapsed === item?.id ? "rotate-180" : "rotate-0"
                        } ease-in duration-300`}
                      >
                        keyboard_arrow_down
                      </span>
                    </button>
                  </div>
                )}
                {headerDetails?.map((ValueItem, indexValue) => {
                  return (
                    <div
                      key={indexValue}
                      className={`${ValueItem?.flexWidthClass}`}
                    >
                      {ValueItem?.customRender ? (
                        ValueItem.renderItem && ValueItem.renderItem(item)
                      ) : (
                        <p className="text-sm text-black">
                          {item[`${ValueItem?.keyToMap}`]}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
              {collapseable && isCollapsed === item?.id && (
                <div className="p-2 pt-0">
                  <div className="p-3 border-2 rounded-lg">
                    {renderCollapse && renderCollapse(item)}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageData, collapseable, headerDetails, isCollapsed]);

  return (
    <div className="w-full flex flex-col bg-white flex-1 rounded-lg border-2 ">
      {renderTableHeader}
      {renderTableContent}
      {renderFooter}
    </div>
  );
}

export default React.memo(TableComponent);
