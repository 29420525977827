import React, { useCallback, useMemo, useState, useEffect } from "react";
import Button from "../components/Button";
import InputComponet from "../components/InputComponet";
import { useDispatch, useSelector } from "react-redux";
import { getProductAction } from "../reduxSetup/productAction";
import { isValidString, isValidElement } from "../utils/productHelper";
import { userLoggedInnSelector } from "../reduxSetup/productSelector";

function AdminDashBoard() {
  const dispatch = useDispatch();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
  });

  const { isUserLoggedInn } = useSelector(userLoggedInnSelector);

  useEffect(() => {
    if (isUserLoggedInn) {
      setUserCredentials({
        email: "",
        password: "",
      });
    }
  }, [isUserLoggedInn]);

  const handleUserLogin = useCallback(() => {
    if (
      isValidString(userCredentials?.email) &&
      isValidString(userCredentials?.password)
    ) {
      dispatch(getProductAction(userCredentials, "GET_USER_DETAILS"));
    }

    // navigate("/ManageProducts");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCredentials]);

  const handleInputChange = useCallback((e, type) => {
    const value = e?.target?.value;
    if (isValidElement(value)) {
      setUserCredentials((prevCredentials) => {
        const updatedCredentials = { ...prevCredentials, [type]: value };
        const areBothValid =
          updatedCredentials.email.length > 4 &&
          updatedCredentials.password.length > 4;

        setBtnDisabled(!areBothValid);
        return updatedCredentials;
      });
    }
  }, []);

  const renderFormFields = useMemo(() => {
    return (
      <div className="w-full  bg-white p-8 rounded-lg	max-w-screen-md border-2 m-auto">
        <div className="mb-5">
          <h2
            className="block text-gray-700 text-lg font-bold mb-2"
            htmlFor="email"
          >
            Login
          </h2>
        </div>
        <div className="mb-3">
          <InputComponet
            value={userCredentials?.email}
            onChange={(e) => handleInputChange(e, "email")}
            type="email"
            label="Email"
            placeholder="Enter your email"
          />
        </div>

        <div className="mb-10 flex items-end ">
          <div className="flex-1 ">
            <InputComponet
              value={userCredentials?.password}
              type={isPasswordVisible ? "text" : "password"}
              label="Password"
              placeholder="Enter your Password"
              onChange={(e) => handleInputChange(e, "password")}
            />
          </div>
          <Button
            styleClass="mb-1 ml-2"
            styleNone={true}
            iconName={isPasswordVisible ? "visibility_off" : "visibility"}
            handlePress={() => setIsPasswordVisible(!isPasswordVisible)}
            iconBtn={true}
          />
        </div>

        <Button
          disabled={btnDisabled}
          styleClass="w-full py-2 px-4 "
          handlePress={handleUserLogin}
          label="Submit"
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    btnDisabled,
    userCredentials?.email,
    userCredentials?.password,
    isPasswordVisible,
  ]);

  return <div className="p-8">{renderFormFields}</div>;
}

export default React.memo(AdminDashBoard);
