import "./App.css";
import MainScreen from "./Screens/MainScreen";
import React from "react";
import { BrowserRouter } from "react-router";
import "./Tailwind.css";
import { Provider } from "react-redux";
import { store, persistor } from "./reduxConfig/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <MainScreen />
            <ToastContainer />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default React.memo(App);
