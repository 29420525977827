import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import AddEditFrame from "./AddEditFrame";

function AddProductScreen() {
  const navigate = useNavigate();

  const handleFormSubmit = useCallback(() => {
    navigate("/ManageProducts");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-10 flex flex-col max-w-[900px] h-full m-auto">
      <div className="flex flex-row justify-start items-center mb-4">
        <Button
          styleNone={true}
          iconName={"arrow_back"}
          handlePress={() => navigate("/ManageProducts")}
          iconBtn={true}
        />

        <h3 className="font-medium text-2xl ml-5 ">Add Product</h3>
      </div>
      <div className="w-full flex flex-col items-between  bg-white flex-1 rounded-lg border-2 ">
        <div className="overflow-y-auto max-h-[520px]  p-5  flex-1">
          <AddEditFrame />
        </div>

        <div className="flex justify-end  p-5">
          <Button
            handlePress={handleFormSubmit}
            styleClass="w-[160px] py-2 px-4"
            label="Add Product"
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(AddProductScreen);
