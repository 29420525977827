import React from "react";

function Button({
  label,
  handlePress,
  styleClass,
  iconBtn,
  iconName,
  styleNone,
  bgColor = "bg-regal-blue",
  disabled = false,
}) {
  return (
    <button
      disabled={disabled}
      onClick={handlePress}
      type={label}
      className={`${styleClass}   ${
        !styleNone &&
        `border-2 text-white  ${
          disabled ? "bg-slate-300" : bgColor
        }  rounded-lg  ${
          !disabled && "hover:text-regal-blue hover:bg-transparent "
        } transition-all duration-300`
      }`}
    >
      {iconBtn ? (
        <span
          className={`material-symbols-outlined  ${
            !styleNone &&
            "text-white text-[18px] hover:text-black transition-all duration-300"
          }`}
        >
          {iconName}
        </span>
      ) : (
        label
      )}
    </button>
  );
}

export default React.memo(Button);
