import React, { useCallback, useMemo, useState } from "react";
import { mockJsonData, footerItems } from "../utils/ProductConstants";
import ListComponent from "../components/ListComponent";
import Modal from "../components/Modal";
import Button from "../components/Button";
import InputComponet from "../components/InputComponet";
import PaginationBtns from "../components/PaginationBtns";
import { isValidElement } from "../utils/productHelper";

function ProductListScreen() {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [submitBtnDisabled, setSubitBtnDisabled] = useState(false);
  const [enquiryData, setEnquiryData] = useState({
    name: "",
    mobileNumber: "",
    message: "",
  });

  const itemsPerPage = 10;

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const totalPages = useMemo(() => {
    return Math?.ceil(mockJsonData?.length / itemsPerPage);
  }, []);

  const currentPageData = useMemo(() => {
    return mockJsonData?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [currentPage]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setSelectedProduct(null);
    setEnquiryData({
      name: "",
      mobileNumber: "",
      message: "",
    });
    // eslint-disable-next-line
  }, []);

  const handleSendEnquiry = useCallback(() => {
    const payload = {
      ...enquiryData,
      vehicleId: selectedProduct?.id,
    };

    console.log(payload, "djiwjd");

    // setEnquiryData({
    //   name: "",
    //   mobileNumber: "",
    //   message: "",
    // });

    // setModalVisible(false);
  }, [enquiryData, selectedProduct]);

  const handleInputChange = useCallback((e, key) => {
    const value = e?.target?.value;
    if (isValidElement(value)) {
      setEnquiryData((prevCredentials) => {
        const updatedCredentials = { ...prevCredentials, [key]: value };
        const areBothValid =
          updatedCredentials?.name?.length > 4 &&
          updatedCredentials?.mobileNumber?.length > 4;

        setSubitBtnDisabled(areBothValid);

        return updatedCredentials;
      });
    }
  }, []);

  const renderModal = useMemo(() => {
    return (
      <Modal
        styles="w-full max-w-[500px]"
        closeModal={closeModal}
        isOpen={modalVisible}
      >
        <div className="p-5 w-full">
          <div className="flex-row flex justify-between items-center mb-3">
            <div className="mb-1">
              <h3 className="font-medium text-xl ">{selectedProduct?.model}</h3>
              <p className="font-medium text-sm text-slate-500 ">
                {selectedProduct?.price} rs
              </p>
            </div>

            <Button
              styleNone={true}
              iconName={"close"}
              handlePress={closeModal}
              iconBtn={true}
            />
          </div>

          <div className="w-full mb-3">
            <InputComponet
              value={enquiryData?.name}
              onChange={(e) => handleInputChange(e, "name")}
              placeholder="Enter your name"
              type="text"
              label="User Name"
            />
          </div>
          <div className="w-full mb-3">
            <InputComponet
              value={enquiryData?.mobileNumber}
              onChange={(e) => handleInputChange(e, "mobileNumber")}
              placeholder="Enter your Phone Number"
              type="number"
              label="Phone Number"
            />
          </div>

          <div className="w-full mb-3">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Message
            </label>
            <textarea
              value={enquiryData?.message}
              onChange={(e) => handleInputChange(e, "message")}
              id="message"
              placeholder="Message"
              className="w-full h-20 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
            ></textarea>
          </div>
          <div className="mt-3 flex justify-end">
            <Button
              disabled={!submitBtnDisabled}
              label={"Send enquiry"}
              styleClass="w-[160px] py-2 px-4"
              handlePress={handleSendEnquiry}
            />
          </div>
        </div>
      </Modal>
    );
    // eslint-disable-next-line
  }, [modalVisible, selectedProduct, enquiryData, submitBtnDisabled]);

  const renderFooter = useMemo(() => {
    return (
      <div className="py-5 bg-white shadow-xl shadow-black flex justify-between items-center">
        {footerItems?.map((item) => {
          return (
            <div
              key={item?.label}
              className="flex items-center gap-2 border-r-2 flex-1 px-5"
            >
              <span className="text-[28px] font-light text-indigo-500 material-symbols-outlined">
                {item?.icon}
              </span>
              <div>
                <label className="text-sm font-medium text-gray-700 mb-1">
                  {item?.label}
                </label>
                <p className="text-[13px] font-medium text-slate-500	 mb-1">
                  {item?.value}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, []);

  const handleModalChange = useCallback((item) => {
    setModalVisible(true);
    setSelectedProduct(item);
  }, []);

  return (
    <div className="">
      <div className="px-5 py-3 border-b-2 sticky top-0 left-0 right-0 bg-white  flex flex-row justify-between items-center">
        <div>
          <h3 className="font-medium text-3xl uppercase">
            Sri Balaji Auto consultant
          </h3>
        </div>
        <div>
          <PaginationBtns
            onPageChange={handlePageChange}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </div>
      <div className="grid p-5  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4    gap-4">
        {currentPageData?.map((item) => {
          return (
            <ListComponent
              onItemPress={() => handleModalChange(item)}
              key={item.id}
              listData={item}
            />
          );
        })}
      </div>
      {renderFooter}
      {isValidElement(selectedProduct) && renderModal}
    </div>
  );
}

export default React.memo(ProductListScreen);
