import { createSelector } from "reselect";
import { isValidString } from "../utils/productHelper";

const productState = (state) => state?.productReducer;

export const userLoggedInnSelector = createSelector([productState], (items) => {
  return {
    isUserLoggedInn: isValidString(items?.authToken),
  };
});
