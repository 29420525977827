import React from "react";
import Button from "./Button";

function ListComponent({ listData, onItemPress }) {
  return (
    <div className="bg-white border-2 gap-2  p-2 rounded-xl">
      <div className="rounded-xl w-[full] h-[160px] overflow-hidden mb-3 ">
        <img
          className="w-[100%] h-[100%] object-cover"
          alt="list-img"
          src={listData?.vehicleImage}
        />
      </div>
      <div className="flex flex-1 flex-col">
        <div className="flex flex-row justify-between items-center mb-3">
          <div>
            <h4 className="text-md font-semibold text-black">
              {listData?.model}
            </h4>
          </div>
          <Button
            styleClass="py-[5px] px-3"
            handlePress={onItemPress}
            label={"Enquire"}
          />
        </div>
        <div className="flex-1 grid   grid-cols-3  gap-1">
          <div>
            <label className="text-[12px] font-semibold text-black">
              Price
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {listData.price} rs
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">
              Make Year
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {listData?.make}
            </h6>
          </div>

          <div>
            <label className="text-[12px] font-semibold text-black">
              Passing
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {listData?.passing}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">
              Reg Number
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {listData?.registrationNumber}
            </h6>
          </div>

          <div>
            <label className="text-[12px] font-semibold text-black">Fc</label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {listData?.fc}
            </h6>
          </div>

          <div>
            <label className="text-[12px] font-semibold text-black">
              Emission Standard
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {listData?.emissionStandard}
            </h6>
          </div>

          <div>
            <label className="text-[12px] font-semibold text-black">
              insurance
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {listData?.insurance}
            </h6>
          </div>

          <div>
            <label className="text-[12px] font-semibold text-black">feet</label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {listData?.feet}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">
              Km Driven
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {listData?.kmDriven}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">
              OwnerShip
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {listData?.ownership}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">
              Body Type
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {listData?.bodyType}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">Tax</label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {listData?.tax}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ListComponent);
