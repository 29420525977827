import React from "react";
import SideBar from "../components/SideBar";
import ProductListScreen from "./ProductListScreen";
import AdminDashBoard from "./AdminDashBoard";
import ManageProducts from "./ManageProducts";
import AddProductScreen from "./AddProductScreen";
import EnquiryListScreen from "./EnquiryListScreen";
import { Routes, Route, Navigate } from "react-router";
import { useSelector } from "react-redux";
import { userLoggedInnSelector } from "../reduxSetup/productSelector";
function MainScreen() {
  const { isUserLoggedInn } = useSelector(userLoggedInnSelector);
  return (
    <div style={{ flexDirection: "row", display: "flex", height: "100vh" }}>
      <SideBar />
      <div style={{ flex: 2, overflowY: "auto", backgroundColor: "#fafafa" }}>
        <Routes>
          <Route path="/" element={<Navigate to="/Home" replace />} />
          <Route path="/Home" element={<ProductListScreen />} />

          <Route
            path="/ManageProducts"
            element={isUserLoggedInn ? <ManageProducts /> : <AdminDashBoard />}
          />
          <Route path="/AddProducts" element={<AddProductScreen />} />
          <Route path="/ViewEnquires" element={<EnquiryListScreen />} />
        </Routes>
      </div>
    </div>
  );
}

export default React.memo(MainScreen);
