import React, { useState, useMemo, useCallback } from "react";
import InputComponet from "../components/InputComponet";
import Button from "../components/Button";

function AddEditFrame({ isSold }) {
  const [image, setImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
  };

  // name: string;
  // date: string;
  // aadhaarPhoto: string;
  // chasisNumber: string;
  // engineNumber: string;
  // customerPhoto: string;
  // mobileNumber: string;
  // rcBook: string;

  const inputData = useMemo(
    () => [
      {
        label: "Vehicle Model",
      },
      {
        label: "Price",
      },
      {
        label: "Registration Number",
      },
      {
        label: "Emission Standard",
      },
      {
        label: "Ownership",
      },
      {
        label: "Km Driven",
      },
      {
        label: "Make",
      },
      {
        label: "Fc Number",
      },
      {
        label: "Insurance",
      },
      {
        label: "Passing",
      },
      {
        label: "Vehicle Feet",
      },
      {
        label: "Vehicle Body Type",
      },
      {
        label: "Tax",
      },
      {
        label: "Vehicle Image",
        inputType: "IMAGE",
      },
    ],

    []
  );

  const soldDetails = useMemo(
    () => [
      {
        label: "Customer Photo",
        inputType: "IMAGE",
      },
      {
        label: "Rc Book Image",
        inputType: "IMAGE",
      },
      {
        label: "Adhar Photo",
        inputType: "IMAGE",
      },
      {
        label: "Customer Name",
      },
      {
        label: "Date",
      },
      {
        label: "Chasis Number",
      },

      {
        label: "Engine Number",
      },

      {
        label: "Mobile Number",
      },
    ],

    []
  );

  const renderInputFields = useCallback(
    (getData) => {
      return (
        <>
          {getData?.map((item, index) => {
            return item?.inputType === "IMAGE" ? (
              <div key={index} className="w-full">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {item?.label}
                </label>
                <div className="flex items-start ">
                  <label
                    htmlFor="file-input"
                    className="relative block w-full h-[150px] border-2 border-dashed rounded-lg overflow-hidden cursor-pointer focus:outline-none"
                  >
                    {image ? (
                      <img
                        src={image}
                        alt="Selected"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="flex flex-col items-center justify-center h-full text-gray-500">
                        <span className="material-symbols-outlined text-[35px]">
                          image
                        </span>
                        <p className="text-xs mt-2">Click to add an image</p>
                      </div>
                    )}
                    <input
                      type="file"
                      id="file-input"
                      accept="image/*"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </label>
                  {image && (
                    <Button
                      bgColor="bg-red-500 text-xs "
                      styleClass="px-4 ml-3 py-2"
                      handlePress={() => setImage(null)}
                      label="Remove Image"
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="w-full">
                <InputComponet placeholder={item?.label} label={item?.label} />
              </div>
            );
          })}
        </>
      );
    },
    [image]
  );

  return (
    <div className="grid  grid-cols-2  gap-4">
      {isSold && renderInputFields(soldDetails)}
      {renderInputFields(inputData)}
    </div>
  );
}

export default React.memo(AddEditFrame);
